@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  direction: rtl;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}